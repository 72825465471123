import React, { useEffect, useState } from "react";
import { getTopper } from "../Service/Api"; 
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import Breadcrumb from '../Component/Breadcrumb';
import LazyLoad from "react-lazyload";

const TopperX = () => {
   const [data, setData] = useState([]);
   const [filterData, setFilteredData] = useState([]);
   const [selectedYear, setSelectedYear] = useState("");
   const [yearRanges, setYearRanges] = useState([]);
   const [searchQuery, setSearchQuery] = useState("");

   useEffect(() => {
      const currentYear = new Date().getFullYear();
      const Year = `${currentYear - 1}-${currentYear}`;
      setSelectedYear(Year); // Initialize with the current year
   }, []);

   useEffect(() => {
      const fetchData = async () => {
         const galleryData1 = await getTopper();
         const galleryData = galleryData1.filter((item) => item.class === 'X');
         setData(galleryData);

         // Extract unique years
         const uniqueYears = Array.from(
            new Set(galleryData.map((item) => item.sessionYear))
         ).sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })); // Sort years

         console.log("Unique Years:", uniqueYears);
         setYearRanges(uniqueYears);

         // Check if the current year's data exists
         const currentYearData = galleryData.find(
            (item) => item.sessionYear === selectedYear
         );

         if (!currentYearData && uniqueYears.length > 0) {
            // Fallback to the most recent year with data
            setSelectedYear(uniqueYears[uniqueYears.length - 1]);
         }

         setFilteredData(galleryData); // Initialize filteredData with all data
      };
      fetchData();
   }, [selectedYear]);

   useEffect(() => {
      // Filter data based on selected year and search query
      let filteredData = data;
      if (selectedYear !== "All") {
         filteredData = filteredData.filter((item) => item.sessionYear === selectedYear);
      }
      if (searchQuery) {
         filteredData = filteredData.filter((item) =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
         );
      }

      // Sort data by division in descending order
      filteredData.sort((a, b) => parseFloat(b.division) - parseFloat(a.division));

      setFilteredData(filteredData);
   }, [selectedYear, searchQuery, data]);

   return (
      <>
         <Header />
         <Breadcrumb title="Topper X " pageName="Topper X " mid="Topper" />

         <div className="innerSection">
            <div className="container">
               <div className="row tabs-dynamic">
                  <div className="col-md-6">
                     <div className="month-selection">
                        <select
                           value={selectedYear}
                           onChange={(e) => setSelectedYear(e.target.value)}
                           className="yearSelection"
                        >
                           <option value={"All"}>All</option>
                           {yearRanges.map((yr) => (
                              <option key={yr} value={yr}>{yr}</option>
                           ))}
                        </select>
                     </div>
                  </div>
                  <div className="col-md-6">
                     <div className="searchBox">
                        <input
                           type="text"
                           id="myInput"
                           name="name"
                           value={searchQuery}
                           onChange={(e) => setSearchQuery(e.target.value)}
                           placeholder="Search Here.."
                        />
                     </div>
                  </div>
               </div>
               <div className="row">
                  {filterData.length > 0 ? (
                     filterData.map((item, index) => (
                        <div className="col-md-3" key={index}>
                           <div className="topper-section services-style-current-theme text-center mb-lg-50 innertopper">
                              <div className="topper-service">
                                 <div className="thumb">
                                    <LazyLoad><img className="img-fluid" src={`https://webapi.entab.info/api/image/${item.attachments}`} alt="Little flower school, gita vatika" /></LazyLoad>
                                 </div>
                                 <div className="details clearfix">
                                    <h3 className="sub_title">{item?.name}</h3>
                                    <p>{item?.stream}</p>
                                    <p>Marks: {item?.division}</p>
                                 </div>
                              </div>
                           </div> 
                        </div>
                     ))
                  ) : (
                     <div className="col-md-3">
                        <div className="topper-section services-style-current-theme text-center mb-lg-50 innertopper">
                           <div className="topper-service">
                              <div className="thumb">
                                 <LazyLoad><img className="img-fluid" src="https://webapi.entab.info/api/image/LFSGV/public/Images/dummy.png" alt="Little flower school, gita vatika" /></LazyLoad>
                              </div>
                              <div className="details clearfix">
                                 <h3 className="sub_title">Student Name</h3>
                                 <p>Stream</p>
                                 <p>Marks</p>
                              </div>
                           </div>
                        </div> 
                     </div>
                  )}
               </div>
            </div>
         </div>
         <Footer />
      </>
   );
};

export default TopperX;
