import { useEffect, useState } from "react";
import { getTopper } from "../Service/Api";
import Slider from "react-slick";
import LazyLoad from "react-lazyload";

const TopperXII = () => {
  const [classXIIData, setClassXIIData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const topperData = await getTopper();
      const currentYear = new Date().getFullYear();
      const yearString = `${currentYear - 1}-${currentYear}`;

      // Filter data for class XII
      let classXII = topperData.filter((item) => item.class === "XII" && item.sessionYear === yearString);

      if (classXII.length === 0) {
        // Fallback: find the most recent year with data
        const uniqueYears = Array.from(
          new Set(topperData.filter((item) => item.class === "XII").map((item) => item.sessionYear))
        ).sort((a, b) => b.localeCompare(a, undefined, { numeric: true, sensitivity: "base" }));

        // Use the most recent year if available
        if (uniqueYears.length > 0) {
          const mostRecentYear = uniqueYears[0];
          classXII = topperData.filter((item) => item.class === "XII" && item.sessionYear === mostRecentYear);
          console.log("Fallback to year:", mostRecentYear);
        }
      }

      setClassXIIData(classXII);
      console.log("Filtered class XII data:", classXII);
    };

    fetchData();
  }, []);

  const settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: 992,
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: 767,
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  return (
    <>
      <div className="row">
        <Slider {...settings}>
          {classXIIData.length > 0 ? (
            classXIIData.map((item, index) => (
              <div className="item" key={index}>
                <div className="topperdiv">
                  <div className="toppertaj">
                    <LazyLoad>
                      <img
                        src={`https://webapi.entab.info/api/image/${item.attachments}`}
                        className="img-fluid"
                        alt={item?.name || "Topper"}
                      />
                    </LazyLoad>
                  </div>
                  <div className="topperdesc">
                    <p className="name">
                      {item?.name}
                      <span>{item?.stream}</span>
                    </p>
                    <p className="per">{item?.division}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="item">
              <div className="topperdiv">
                <div className="toppertaj">
                  <LazyLoad>
                    <img
                      src="https://webapi.entab.info/api/image/LFSGV/public/Images/topper.jpg"
                      className="img-fluid"
                      alt="Placeholder Topper"
                    />
                  </LazyLoad>
                </div>
                <div className="topperdesc">
                  <p className="name">
                    Student Name<span>Stream</span>
                  </p>
                  <p className="per">Marks</p>
                </div>
              </div>
            </div>
          )}
        </Slider>
      </div>
    </>
  );
};

export default TopperXII;
